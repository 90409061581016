<template>
  <div class="component__upload">
    <el-upload
      :headers="headers"
      :action="action"
      :on-success="success"
      :on-remove="remove"
      :file-list="fileList"
      list-type="picture-card"
      :class="['upload', { 'hide-upload': hideUpload }]"
      :limit="1"
      :show-file-list="true"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
import localStore from 'store'
import { baseUrl } from '@/config/env'

export default {
  props: {
    file: Object
  },
  model: {
    prop: 'file',
    event: 'change'
  },
  data() {
    return {
      action: baseUrl + '/upload'
    }
  },
  computed: {
    headers() {
      return {
        token: localStore.get('feisi-user2')?.token
      }
    },
    fileList() {
      if (this.file?.url) return [this.file]
      return []
    },
    hideUpload() {
      return this.fileList.length > 0
    }
  },
  methods: {
    success(_, file) {
      this.$emit('change', file)
    },
    remove() {
      this.$emit('change', null)
    }
  }
}
</script>

<style scoped lang="scss">
.component__upload {
  ::v-deep .hide-upload .el-upload {
    display: none;
  }
}
</style>
